import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import commonHelper from '@/app/utility/common.helper.utility';
import {
  getCountryList,
  getPincodesByStateId,
  updatePincodeData,
  addPincodeData,
  getCountryState
} from '@/app/services/api';

export default {
  name: 'Pincode',
  props: {
    showModalState: Boolean,
    formState: String
  },
  components: {
    ModelSelect
  },
  watch: {
    defaultCountryId: function() {
      this.getCountryState();
    },
    defaultStateId: function() {
      this.currentPage = 1;
      this.getFilteredPincode();
    },
    currentPage: function() {
      this.getFilteredPincode();
    },
    search: function() {
      this.currentPage = 1;
      if (this.search && this.search.length >= 3) {
        this.getFilteredPincode();
      }
      if (this.search.length === 0) {
        this.getFilteredPincode();
      }
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFilteredPincode();
    }
  },
  data() {
    return {
      payload:null,
      unsubscribe: null,
      search: '',
      data: [],
      stateId: '',
      defaultCountryId: '',
      defaultStateId: '',
      pincodeForm: {
        country: {
          value: '',
          text: ''
        },
        state: {
          value: '',
          text: ''
        },
        pincode: ''
      },
      stateCountryData: [],
      pincodeStateData: [],
      pincodesStateData: [],
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      stickyHeader: true,
      totalRows: null,
      filterOn: [],
      showModal: false,
      editMode: false,
      editState: false,
      responseMsg: '',
      isSuccess: false,
      isFailed: false,
      update: false,
      showAlert: false,
      fields: [
        {
          key: 'country',
          sortable: true
        },
        {
          key: 'state',
          sortable: true
        },
        {
          key: 'pincode',
          sortable: false
        }
      ]
    };
  },
  validations: {
    pincodeForm: {
      country: {
        value: {
          required
        },
        text: {
          required
        }
      },
      state: {
        value: {
          required
        },
        text: {
          required
        }
      },
      pincode: {
        required
      }
    }
  },

  mounted() {
    this.getCountryList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
          this.update = true;
        }

        if (actionName === 'add') {
          this.resetForm();
          this.showAlert = false;
          this.showModal = true;
          this.editMode = true;
          this.update = false;
        }

        if (actionName === 'save') {
          this.updatePincodeData();
        }
        if (actionName === 'download' && !this.showModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'country/getFilteredPincodeList',
            'pincode',
            () => (this.loader = false)
          );
        }
        if (actionName === 'upload' && this.showModal) {
          this.eventBus.$emit('commonUpload', { id: this.pincodeId });
        }
      }
    });
  },
  methods: {
    async getCountryList() {
      try {
        const response = await getCountryList();
        const results = response.data.data;
        const stateCountry = results.map(type => {
          return {
            value: type.country_id,
            text: type.country
          };
        });
        this.stateCountryData = stateCountry;
        if (
          this.stateCountryData !== null &&
          this.stateCountryData.length > 0
        ) {
          this.defaultCountryId = this.stateCountryData[0].value;
          this.getCountryState();
        }
      } catch (err) {
        this.err = err;
      }
    },

    async getCountryState() {
      try {
        const response = await getCountryState(this.defaultCountryId);
        const results = response.data.data;
        const pincodeCountry = results.map(type => {
          return {
            value: type.state_id,
            text: type.state
          };
        });
        this.pincodeStateData = pincodeCountry;
        if (
          this.pincodeStateData !== null &&
          this.pincodeStateData.length > 0
        ) {
          this.defaultStateId = this.pincodeStateData[0].value;
        }
      } catch (err) {
        this.err = err;
      }
    },

    async getFilteredPincode() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        state_id: this.defaultStateId,
        pincode: this.search
      };
      if (this.defaultStateId) {
        try {
          const response = await getPincodesByStateId(
            this.defaultStateId,
            this.currentPage - 1,
            this.perPage,
            this.search
          );
          const results = response.data.data.page;
          this.data = response.data.data.page;
          this.totalRows = response.data.data.total_elements;
          const pincode = results.map(type => {
            return {
              value: type.pincode_id,
              text: type.pincode
            };
          });
          this.pincodesStateData = pincode;
        } catch (err) {
          this.err = err;
        }
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.pageOptions[5].value = filteredItems.length;
    },
    resetModal() {
      this.$emit('clicked', 'view');
      this.editMode = false;
      this.pincodeForm.pincode = '';
      this.update = false;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    rowSelected: function(item) {
      this.showModal = true;
      this.showAlert = false;
      this.update = true;
      this.pincodeId = item.pincode_id;
      this.pincodeForm.country.text = item.country;
      this.pincodeForm.country.value = item.country_id;
      this.pincodeForm.state.text = item.state;
      this.pincodeForm.state.value = item.state_id;
      this.pincodeForm.pincode = item.pincode;
    },
    created: function() {
      this.getCountryList(), this.getCountryState();
    },
    updatePincodeData: function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$emit('clicked', 'edit');
        this.update = false;
        return;
      } else {
        const requestBody = {
          state_id: this.pincodeForm.state.value,
          pincode: this.pincodeForm.pincode
        };
        if (this.update) {
          updatePincodeData(requestBody, this.pincodeId)
            .then(response => {
              if (response && response.status === 200) {
                this.showAlert = true;
                this.isSuccess = true;
                this.editMode = false;
                this.update = false;
                this.responseMsg = appStrings.pincodeSuccessMsg;
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = appStrings.autoFailedMsg;
              }
            })
            .catch(err => {
              this.err = err;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        } else {
          addPincodeData(requestBody)
            .then(response => {
              if (response && response.status === 201) {
                this.showAlert = true;
                this.isSuccess = true;
                this.editMode = false;
                this.update = false;
                this.responseMsg = appStrings.pincodeAddedMsg;
              } else {
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = appStrings.autoFailedMsg;
              }
            })
            .catch(err => {
              this.err = err;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
    },
    resetForm() {
      this.pincodeForm = {
        country: {
          value: '',
          text: ''
        },
        state: {
          value: '',
          text: ''
        },
        pincode: ''
      };
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
